.breadcrumbs {
  margin-left: 0.5em;
  margin-bottom: 4px;

  .breadcrumb+.breadcrumb {
    margin-left: 0.5em;
  }
  
  .breadcrumb:not(:last-child):after {
    content: '>';
    margin-left: 0.5em;
  }  
  
  .breadcrumb {
    a {
      color: white;
      text-decoration: none;
    }
    a:hover {
      color: gray;
    }
  }

  @media (max-width: 550px) {
    .breadcrumb:first-child {
      display: none;
    }
    .breadcrumb+.breadcrumb {
      margin-left: 0;
    }
  }  
  @media (max-width: 368px) {
    .breadcrumb:not(:last-child) {
      display: none;
    }
  }
    
}
