
.cards {
  user-select: none; // prevent long press from highlighting text
  overflow: auto;
  padding: 5px 0 0 5px; // give space for glow effect
}

.cards-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

// had tried .cards-list as a css grid, but couldn't get them to have same widths.
// so just using float to arrange things in a grid.
// .cards-list {
// }

// get width of each card based on 
//   .cards margin-left 1em
//   .card-container margin-right 1em
//   so (ncols+1) * 1em for horizontal spacing
//   then divide the remaining space by ncols

.card-container {
  float: left;
  width: calc(calc(100% - 1em) / 1);
  margin: 0 1em 1em 0;
}
@media screen and (min-width: 450px) {
  .card-container {
    width: calc(calc(100% - 2em) / 2);
  }
}
@media screen and (min-width: 700px) {
  .card-container {
    width: calc(calc(100% - 3em) / 3);
  }
}
@media screen and (min-width: 900px) {
  .card-container {
    width: calc(calc(100% - 4em) / 4);
  }
}

.card {
  background: white;
  // background: #f5f5f5;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 1em;
  text-align: center;
  height: 3.5em;
  display: flex;
  justify-content: center; // center text horizontally
  align-items: center; // center text vertically
  box-shadow: 4px 4px 5px rgba(0,0,0,.15);
  border-radius: 3px;
  position: relative;
}
.card:hover {
  box-shadow: 0px 0px 12px #aac8ff !important;
}
.card.highlighted {
  box-shadow: 0px 0px 10px limegreen;
}
.card.highlighted.negative {
  box-shadow: 0px 0px 10px red;
}

.card-image {
  position: absolute;
  top: 4px;
  img {
    // height: 100%;
    // width: auto;
    height: 86px;
    width: 86px;
    object-fit: contain;
  }
}

.card-pg-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 4px;
  justify-content: space-around;
  button + button {
    margin-left: 4px;
  }
}
