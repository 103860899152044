
$break: 600px;

.MaintainStores {
  height: 100%;

  .MaintainStores-header {
    font-size: 0.9em;
  }

  .MaintainStores-header button {
    margin: 4px;
  }

  .header {
    // height: 8em; //. try
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

}

.addStore {
  background: white;
  // width: 50%;
  width: 20em;
  max-width: 90%;
  padding: 1em;
  h3 {
    margin: 0;
  }
  input[type="text"] {
    max-width: 90%;
  }
  .select-company {
    margin: 10px 0;
    p {
      margin: 5px 0;
    }
  }
  .addStore-options {
    div {
      p {
        // opacity: 65%;
        color: #777;
        margin: 5px 0;
      }

      display: flex;
      flex-direction: column;
    }

    div:last-child {
      margin-top: 5px;
      flex-direction: row;

      input {
        margin: 10px;
      }
    }
  }
}
.addStore-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
