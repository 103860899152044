$break: 700px;

.display-count-header {
  // position: sticky;
  // top: 0; // need this with position sticky
  // z-index: 1; // ditto
  // background: inherit; // ditto
  // overflow: auto;
  // height: 410px;

  // fix ipad issues - didn't work with position:sticky
  position: absolute;
  left: 0.5em;
  height: 4em; // see also .AdjustMode margin-top - keep in synch
  // width: calc(100% - 1em); // leave space for scrollbar
  z-index: 1;
  background: inherit;

  padding: 4px 1em 4px 0;
  user-select: none;

  .top {
    display: flex;
    flex-direction: row;

    float: none;
    justify-content: space-between;
    align-items: center;

    // @media (min-width: $break) {
    //   float: none;
    //   justify-content: space-between;
    // }

    .buttons {
      display: flex;
      align-items: center;
      // padding-left: 1em;

      button {
        height: 32px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
      }
      button + button {
        margin-left: 4px;
      }
      .ItemSearchDropdown {
        margin-left: 8px;
      }
    }
  }

  .camera-button {
    margin-left: 8px;
    width: 9em;
    img {
      height: 24px;
      margin-right: 6px;
    }
  }

  .filter {
    margin-left: 8px;
    span {
      display: none;
      @media (min-width: $break) {
        display: inline;
      }
    }
    input[type='text'] {
      border-radius: 3px;
      width: 8em;
      min-height: 30px;
      border: 1px solid #aaa;
      padding: 4px 8px;
      font-size: 16px;
    }
  }
}
