.file-reader {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* justify-content: center; */
  justify-content: space-between;
}
.file-reader input {
  display: none;
}
.file-reader label {
  text-align: center;
}
.file-reader-name {
  color: gray;
}
.file-reader-button {
  /* appearance: button; */
  background: #f0f0f0;
  border: 1px solid black;
  border-radius: 2px;
  padding: 2px 8px;
  cursor: pointer;
}
.file-reader-button:hover {
  background: #e0e0e0;
}
