.progress {
  background: white;
  padding: 1em;
  width: 80%;
  user-select: none; /* prevent long press from highlighting text */
  z-index: 999;
}
.progress-title {
  font-weight: bold;
  margin-bottom: 1em;
}
.progress-message {
  margin-bottom: 1em;
}
.progress button {
  float: right;
}
