.showImage {
  background: white;
  height: 90%;
  width: 90%;
  padding: 1em;
  text-align: center;
  overflow: auto;
  position: relative;

  .close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  h3 {
    margin: 0;
  }
  p {
    font-size: 0.9em;
    color: #444;
  }
  .image {
    img {
      object-fit: cover;
      width: 90%;
    }
  }
}
// .showImage-buttons {
//   // display: flex;
//   // justify-content: space-between;
// }
// .showImage-buttons button+button {
//   margin-left: 0.5em;
// }
