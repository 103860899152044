
.alert {
  background: white;
  padding: 1em;
  max-width: 25em;
  margin: 0.5em;
  // user-select: none; // prevent long press from highlighting text
  user-select: text; // allow selecting text so can copy
  z-index: 999;
  max-height: 90%;
  overflow-y: auto;
  
  .message {
    margin-bottom: 1em;
  }
  
  button {
    float: right;
  }
}
