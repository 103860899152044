
.confirm {
  background: white;
  padding: 1em;
  max-width: 25em;
  margin: 0.5em;
  // user-select: none; // prevent long press from highlighting text
  user-select: text; // allow selecting text so can copy
  z-index: 999;
  max-height: 90%;
  overflow-y: auto;
 
  .title {
    font-weight: bold;
  }
  .message {
    margin-bottom: 1em;
  }
 
  // button {
  //   float: right;
  // }
  // button+button {
  //   margin-right: 8px;
  // }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
