$header-height: 2em;

.ShowStores {
  padding: 0 0 0 1em;
  background: inherit;
  overflow: auto;

  .header {
    // sticky doesn't work on ios yet
    // position: sticky;
    // top: 0;
    // z-index: 1;
    // background: inherit;
    // padding: 0.5em 1em 0.5em 0;
    padding-top: 0.5em;

    // fix ipad issues
    position: absolute;
    left: 0.5em;
    height: $header-height;
    width: calc(100% - 1em); // leave space for scrollbar
    z-index: 1;
    background: inherit;

    user-select: none; // prevent longpress from selecting any text

    display: flex;
    justify-content: space-between;
  }

  .cards-list {
    margin-top: calc(#{$header-height} + 1em); // 1em for the header padding
  }
  
}


.StoreCard {
  // // show icon
  // background-image: url('../../assets/images/store.svg');
  // background-repeat: no-repeat;
  // background-size: 20px 20px;
  // background-position: 6px 6px;
  // box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.7); // dim out the icon a bit

  // // show image
  // background-image: url('../../assets/images/store2.jpg');
  // background-repeat: no-repeat;
  // background-size: auto 100%;
  $imwidth: 65px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: $imwidth;
    height: 100%;
    object-fit: cover;
  }

  .StoreCard-name {
    // width: 100%;
    // background-color: rgba(0,0,0,0.3);
    width: calc(100% - #{$imwidth});
    position: absolute;
    left: $imwidth;
    // top: 14px;
  }
}
