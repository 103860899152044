.getObject {
  background: white;
  width: 24em;
  height: 88%;
  padding: 0.5em 1em;
  margin: 0 auto;
  overflow-y: auto;

  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .instructions {
    color: gray;
    margin-bottom: 0.5em;
  }

  table {
    width: calc(100% - 4px);
  }
  table tr {
    width: 100%;
  }
  table td {
    vertical-align: top;
  }
  .field {
    // font-weight: bold;
    padding-right: 1em;
    // vertical-align: middle;
  }
  .value {
    // padding: 0;
    // width: 100%;
  }
  select,
  input[type='text'],
  textarea {
    display: inline-block;
    width: 100%;
    // border: none;
  }
  // input[type="text"] {
  //   height: 22px;
  // }
  // textarea {
  //   height: 90px;
  // }
  img {
    height: 8em;
  }
  .buttons {
    margin: 1em auto;
    text-align: center;
  }
  .buttons button + button {
    margin-left: 8px;
  }
  button {
    font-size: 1.2em;
  }
}
