.menu {
  cursor: pointer;
}
.menu-stores {
  font-size: 0.7em;
}

// override rc-menu settings

.rc-menu {
  margin: 0;
}
.rc-menu-submenu {
  cursor: pointer;
  z-index: 2; // card headers are at 1
}
.rc-menu-submenu-selected {
  background-color: transparent !important;
}
.rc-menu-submenu-title {
  // background-color: transparent;
  // background-image: url("../../assets/images/tallieo.png");
  background-image: url("../../assets/images/person.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 35px;
  background-size: cover;
  z-index: 1;
}
.rc-menu-submenu.rc-menu-submenu-popup {
  top: 40px !important; // otherwise leaves a gap before showing popup menu
}
.rc-menu-horizontal > .rc-menu-submenu {
  border-bottom: none;
}
.rc-menu-item img {
  width: 40px;
  margin-right: 8px;
  float: left;
}
.rc-menu-horizontal {
  border-bottom: none;
  background-color: transparent;
}
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 0 10px;
}
.rc-menu-vertical > .rc-menu-item {
  padding: 4px 8px 4px 24px;
}
.rc-menu-sub {
  // margin: 0;
}
.rc-menu > .rc-menu-item-divider {
  background-color: #bbb;
}

