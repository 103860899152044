.getString {
  background: white;
  // width: 50%;
  width: 20em;
  max-width: 90%;
  padding: 1em;
  h3 {
    margin: 0;
  }
  input[type="text"] {
    max-width: 90%;
  }
}
.getString-buttons {
  display: flex;
  justify-content: space-between;
}
.getString-buttons button+button {
  margin-left: 0.5em;
}
