.OrderThankyou {
  margin: 2em auto;
  padding: 1em;
  max-width: 32em;
  text-align: center;
  
  .continue {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 4em;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }
}
