// need to make this dialog look good on phone, tablet, and laptop!

.getItemCount {

  position: absolute;
  // top: 1.8em;
  text-align: center;
  padding: 8px;
  width: 90%;
  // height: calc(100% - 3em);
  height: 96%;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .info {
    color: #608aff;
    cursor: pointer;
  }
  .info:hover {
    color: #4af;
  }

  .subtitle {
    font-weight: bold;
  }

  .header {
    position: relative;
    border-bottom: 1px solid #ddd;
    .code {
      margin: auto;
      max-width: 90%;
      font-weight: bold;
    }
    .code+span {
      font-weight: bold;
    }
    .name {
      margin: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-size: 0.9em;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }

  .contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .left {
      flex-basis: 33%;
      padding-right: 1em;
      img {
        object-fit: cover;
        // width: 14em;
        // height: 14em;
        width: 21em;
        height: 21em;
        // these don't work for some tall images
        // width: 100%;
        // height: 100%;
        // max-width: 24em;
        // max-height: 24em;
      }
    }
    .center {
      flex-basis: 33%;
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .right {
      flex-basis: 33%;
      // width: 14em;
      padding-left: 1em;
    }
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    // width: 100%;

    .image {
      img {
        display: block;
        object-fit: contain;
        height: 7em;
        width: 7em;
        // height: 8em;
        // width: 8em;
      }
    }
    .displayValues {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .trayValues {
      text-align: right;
      font-size: 0.9em;
      td {
        padding: 0 4px;
        vertical-align: top;
      }
      .totalInStore td {
        padding-bottom: 10px;
      }
    }
  }

  .middle {
    .current-value {
      .value {
        font-size: 30pt;
        vertical-align: middle;
        display: inline-block;
        min-width: 1.6em;
        height: 1em;
        text-align: center;
      }
      button {
        height: 2em;
        padding: 8px;
      }
    }
    .history {
      font-size: 0.9em;
    }
  }

  .bottom {
    .keypad {
      width: 14em;
      margin: auto;
      margin-bottom: 4px;
      .hg-button {
        height: 36px;
      }
    }
    .plusMinusButtons {
      display: flex;
      button {
        width: 100%;
      }
    }
  }

  .middle button,
  .bottom button,
  .footer button {
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
    cursor: pointer;
    touch-action: manipulation; // don't zoom on double tap on ipad
    &:hover {
      background: rgb(223, 234, 255);
    }
    &:disabled {
      background: #f0f0f0;
      color: #bbb;
    }
    &:active {
      border: none;
      outline: none;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    button+button {
      margin-left: 8px;
    }
    button {
      width: 8em;
      img {
        height: 58px;
        width: 58px;
        object-fit: contain;
        margin: 2px;
      }
      display: flex;
      justify-content: center;
    }
  }

  .popup-content {
    box-shadow: 4px 4px 4px gray !important;
  }
  .infobox {
    // width: 20em; //.
    text-align: left;
  }
  .displayCounts {
    margin-top: 1em;
    table td {
      padding-right: 0.5em;
    }
  }

  .previousInventory {
    margin-top: 1em;
    font-weight: normal;
    table {
      border-spacing: 0;
    }
    table td {
      vertical-align: top;
      padding-right: 0.5em;
    }
    a {
      text-decoration: none;
      color: teal;
      &:hover {
        color: skyblue;
      }
    }
  }



  .delta {
    color: #0d0;
  }
  .delta.negative {
    color: red;
  }
  .newAmount {
    height: 1em;
    padding: 3px;
    line-height: 0.9em;
  }  

}
