
.reactAsyncDialog-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.alert {
  background: white;
  padding: 1em;
  min-width: 10em;
}
.alert-message {
  margin-bottom: 1em;
}
.alert button {
  float: right;
}


.getString {
  background: white;
  width: 20em;
  padding: 1em;
}
.getString h3 {
  margin: 0;
}
.getString-buttons {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}
.getString-buttons button+button {
  margin-left: 0.5em;
}

