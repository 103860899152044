// found free optima-like font here, which is similar to Pandora's font they use
// https://www.fontpalace.com/font-details/Optane/
// then used font-squirrel to convert it to woff and woff2
// https://www.fontsquirrel.com/tools/webfont-generator
// (woff are compressed ttf files, woff2 is version 2 with better compression)
@font-face {
  font-family: "Optane";
  src: url("../../assets/fonts/optane-webfont.woff2") format("woff2"),
    url("../../assets/fonts/optane-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pandora Text';
  src: url('../../assets/fonts/PanText-Regular.woff2') format('woff2');
  // src: url('../../assets/fonts/PanDisplay-Light.woff2') format('woff2');
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Optane, Optima, sans-serif;
  // futura looks pretty good - could use for TALLIEO at least?
  // font-family: Futura, Optane, Optima, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 20px;
  color: black;
  user-select: none; // otherwise press on card selects text
  -webkit-touch-callout: none; // disable press image menu
}

html,
body,
#root,
.app,
.app > .contents {
  height: 100%; // ag-grid needs this
  background: #fcdcd8; // sticky elements need to inherit from parent's background color
}

table {
  border-spacing: 0;
}

.app {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

// global classes

.green {
  color: #0d0;
}
.red {
  color: red;
}
.orange {
  color: darkorange;
}
.gray {
  color: #aaa;
}
.purple {
  color: purple;
}
.yellowgreen {
  color: yellowgreen;
}

// turn off orange focus outline for phones
// see https://stackoverflow.com/questions/5210481/disable-orange-outline-highlight-on-focus
button {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

.bordered-table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  th {
    border: 1px solid #ddd;
    // font-weight: bold;
    padding-right: 1em;
  }
  td {
    border: 1px solid #ddd;
    text-align: left;
    padding-right: 1em;
  }
}

.orderApp {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Pandora Text', Optane, Optima, sans-serif;
}
.orderApp-contents {
  flex-grow: 1;
  overflow: auto;
}
