.OrderStart {
  margin: auto;
  padding: 1em;
  max-width: 30em;
  text-align: center;
  
  .logo {
    text-align: center;
    margin-bottom: 1em;
  }
  .logo img {
    height: 32px;
  }

  .continue {
    text-align: center;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }
}
