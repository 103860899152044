.MaintainCategories {

  height: 100%;
  
  .header {
    padding: 4px;
    display: flex;
    justify-content: space-between;
    .show-status {
      margin-left: 1em;
    }
    .right {
      display: flex;
      align-items: center;
      span+button {
        margin-left: 4px;
      }
    }
  }
  
  button+button {
    margin-left: 4px;
  }
}
