.OrderContact {
  margin: auto;
  padding: 1em;
  max-width: 32em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    width: 100%;
    max-width: 16em;
    margin: auto;
  }

  table {
    // border-spacing: 1em 0;
    width: 100%;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    width: 100%;
    border-radius: 3px;
    height: 1.4em;
    border: 1px solid gray;
    margin-bottom: 0.7em;
  }
  input[type='checkbox'] {
  }

  .state {
    width: 3em;
  }
  .zipcode {
  }

  .options {
    div {
      margin-bottom: 6px;
    }
    label {
      padding: 0 12px 0 8px;
      margin-bottom: 4px;
    }
    margin-bottom: 0.5em;
  }

  .acknowledgement {
    label {
      margin-left: 1em;
    }
  }

  .promotions {
    label {
      margin-left: 1em;
    }
  }

  .continue {
    text-align: center;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }
}
