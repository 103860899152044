#scanner-container {
  display: none;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#scanner-container.visible {
  display: block;
}

#scanner-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

#scanner-search {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: auto;
}
#scanner-search-inner {
  position: absolute;
  border: 2px solid #fe8e14;
  border-radius: 3px;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
}

#scanner-canvas {
  /* position: absolute; */
  /* z-index: 20; */
  /* display: none; */
  visibility: hidden;
}

#scanner-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 30;
  cursor: pointer;
}
#scanner-close:hover {
  background: #aaa;
}
