$imgsize: 110px;
$left: $imgsize + 4px;
// $height: $imgsize + 8px;
$height: $imgsize + 28px;

.OrderFavorites {
  padding: 1em;
  max-width: 20em;
  margin: auto;

  .header {
    margin-bottom: 1em;
    text-align: center;
  }
  
  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  // need to override this width to center them on page
  .cards3 {
    width: 14em;
    @media screen and (min-width: 375px) {
      width: 16em;
    }
    @media screen and (min-width: 400px) {
      width: 18em;
    }
  }

  .totals {
    margin: auto;
    td+td {
      text-align: right;
    }
    td {
      padding: 0 1em;
    }
    margin-bottom: 1em;
  }

  .continue {
    text-align: center;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }

  .card3 {
    padding: 4px;
    background: #fafafa; // in between f5f5f5 and white - some have one some the other
    // max-width: 18em;
    width: 100%;
    height: $height;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;

    .sizeAndQuantity {
      font-size: 0.8em;
      color: #444;
      text-align: left;
      td {
        padding-right: 4px;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 4px;
      padding-left: 8px;
    }

    .image {
      padding: 0;
      img {
        height: $imgsize;
        width: $imgsize;
        object-fit: contain;
      }
    }
    .name {
      font-size: 0.9em;
    }
    .code {
      font-size: 0.8em;
      color: gray;
    }
    // .quantity {
    //   margin-top: 4px;
    //   font-size: 0.8em;
    //   color: #444;
    //   span+select {
    //     margin-left: 8px;
    //   }
    // }
    // .size {
    //   font-size: 0.8em;
    // }
    // .price {
    //   font-size: 0.7em;
    //   color: gray;
    // }
    .size-price {
      width: 100%;
    }

    .heart {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 1.4em;
    }
  }
}
