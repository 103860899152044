.commissions {
  background: white;
  overflow: auto;
  flex-basis: 100%;

  .page {
    padding: 1em 2em 3em;
    max-width: 960px;
    margin: auto;
  }

  .title {
    font-weight: bold;
  }

  .input {
    padding-bottom: 1em;
    position: relative;
  }

  .file {
    position: relative;
    color: gray;
  }
  ::-webkit-file-upload-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  table {
    margin-top: 0.5em;
    border-collapse: collapse;
    tr th {
      text-align: left;
      padding: 0 0.5em;
    }
    tr td {
      border: 1px solid #ccc;
      // padding: 0 0.25em 0 0;
      padding: 0.5em;
      vertical-align: top;
      text-align: center;
    }
    tr td:nth-child(1) {
      text-align: left;
    }
  }

  .calculate {
    margin-top: 1em;
    text-align: right;
  }
}
