
.login {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  form {
    text-align: right;
  }
  table {
    text-align: left;
  }
}

.login label {
  display: block;
  margin-bottom: 1em;
}

.login input[type="text"],
.login input[type="email"],
.login input[type="password"]
{
  display: block;
  width: 14em;
  padding: 0 4px;
}
.login input[type="text"]:focus,
.login input[type="email"]:focus,
.login input[type="password"]:focus
{
  box-shadow: 0 0 6px #b6b6ff;
}

.login-camera {
  padding-left: 16px;
  cursor: pointer;
}
.login-camera svg {
  transform: scale(1.5);
}

.login-button {
  text-align: right;
}
.login-button input {
  margin-top: 1em;
  padding: 1px 1.5em;
  margin-right: 4px;
}

.login-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.reset-password {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 1rem;
}
