.OrderStores {
  margin: auto;
  padding: 1em;

  .header {
    // font-size: 1.1em;
    margin-bottom: 1em;
  }

  .card {
    .StoreCard-name {
      // top: 35px;
    }
    // .phone {
    //   color: gray;
    //   font-size: 0.8em;
    //   position: absolute;
    //   top: 60px;
    //   left: 65px;
    //   right: 4px;
    // }
  }
}
