.inventoryBegin {
  height: 70%; // reduced from 100% to fit keyboard on ipad
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .info {
    margin-bottom: 2em;
    max-width: 90%;
  }
  .info svg {
    vertical-align: text-top;
  }
  table {
    margin-bottom: 4em;
  }
  .continue {
    float: right;
    span {
      cursor: pointer;
      &:hover {
        color: gray;
      }
    }
  }
  @media (max-width: 550px) {
    .info {
      font-size: 0.9rem;
      text-align: center;
    }

    td, th {
      text-align: center;
      display: block;
      width: 100%;
    }

    tr {
      display: block;
      margin-bottom: 1em;
    }
  }
}
