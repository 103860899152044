.OrderCategories {
  padding: 1em;
  .header {
    margin-bottom: 1em;
  }

  .continue {
    text-align: center;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }
  
  .CategoryCard {
    background: #fafafa;
    img {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 100px;
      height: 100px;
    }
    .CategoryCard-name {
      font-size: 0.9em;
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      left: 104px;
      right: 4px;
    }
  }
}
