.MaintainItems {
  padding: 0 0 0 0.5em;
  background: inherit;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;

  .header {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
    }

    @media (max-width: 360px) {
      select {
        width: 9em;
      }  
    }

    input {
      margin-left: 10px;
      width: 8em;
    }

    .Help {
      margin-left: 4px;
    }
    
  }

  .ItemCard {
    // // show icon
    // background-image: url('../../assets/images/store.svg');
    // background-repeat: no-repeat;
    // background-size: 20px 20px;
    // background-position: 6px 6px;
    // box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.7); // dim out the icon a bit
  
    // // show image
    // background-image: url('../../assets/images/store2.jpg');
    // background-repeat: no-repeat;
    // background-size: auto 100%;
    $imwidth: 100px;
    $left: 104px;
  
    img {
      position: absolute;
      top: 0;
      left: 4px;
      width: $imwidth;
      height: 100%;
      object-fit: contain;
    }
  
    .code {
      font-size: 0.8em;
      font-weight: bold;
      position: absolute;
      top: 8px;
      left: $left;
      right: 4px;
    }
    .name {
      width: calc(100% - #{$left} - 4px);
      position: absolute;
      // top: 40px;
      left: $left;
      font-size: 0.8em;
    }
    .price {
      font-size: 0.7em;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }
}
