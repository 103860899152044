
.password-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2em;

  input {
    margin-right: 0.5em;
  }
}
