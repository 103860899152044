
// disable events from children so only top-level data-long-press-delay value 
// will be used.
// see https://github.com/john-doherty/long-press-event/issues/14
// it works but prevents the sub-buttons from receiving any events also...
.pressable div,
.pressable span,
.pressable button,
.pressable img {
  // pointer-events: none;
}

.pressable {
  user-select: none;
}
