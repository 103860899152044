.ShowItems {
  .card-buttons {
    button {
      width: 2em;
      height: 3em;
      background: transparent;
      border: 1px solid #eee;
      pointer-events: all; // override setting in packages/pressable scss, which see
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button:hover {
      background: #ddf;
    }
    .card-minus {
      // font-size: 1.1em;
      position: absolute;
      bottom: 8px;
      left: 12px;
    }
    .card-plus {
      position: absolute;
      bottom: 8px;
      right: 12px;
    }
  }
  .card-delta, .card-discrepancy {
    font-size: 0.9em;
    color: #0c0;
    position: absolute;
    top: 8px;
    left: 8px;
    &.negative {
      color: red;
    }
  }
  // .card-delta.negative {
  //   color: red;
  // }
  .card-code {
    font-size: 0.75em;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .card-finalCount {
    // font-size: 0.75em;
    font-size: 0.9em;
    position: absolute;
    top: 8px;
    right: 8px;
    // width: 1em;
    height: 1em;
    padding: 2px;
    // border: 1px solid gray;
    // border-radius: 50%;
    line-height: 0.8em;
  }
}
