
.pagination {
  display: flex;
  padding: 8px 4px;
  justify-content: space-evenly;
  align-items: center;
  button+button {
    margin-left: 4px;
  }
}
