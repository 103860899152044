
$break: 600px;

.InventoryDiscrepancies {

  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .header {
    // height: 8em; //. try
    flex-basis: 8em; //. try
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $break) {
      // height: 4em; //. try
      flex-basis: 4em; //. try
      flex-direction: row;
      align-items: center;
      .select-stores {
        margin-left: 1em;
        // width: 50vw;
      }
      button {
        margin-left: 8px;
      }
    }

    .select-dates {
      width: 230px; // the control has fixed px widths, so set this
      >div {
        white-space: nowrap;
      }
    }

    .select-stores {
      width: 100%;
      max-width: 25em;
      >div {
        white-space: nowrap;
      }
      .css-2b097c-container {
        width: 100%;
        font-size: 1rem;
      }
      .css-13jmfyo-multiValue {
        background-color: rgba(167, 195, 255, 0.5);
      }
    }

    .Help {
      height: auto; // override the 100%
    }

  }


  .footer {
    // height: 3em; //. try
    flex-basis: 3em; //. try
    display: flex;
    padding: 2px 4px;
    font-size: 1.2rem;

    table {
      td {
        text-align: left;
        vertical-align: top;
        border: 0px;
        padding: 4px 1em 4px 4px;
      }
    }

    @media (max-width: 550px) {
      font-size: 1rem;
    }
  }

  .number {
    text-align: right;
  }


  .ag-header-cell-text {
    white-space: normal; // let header text wrap for multiline
  }

  // $bg: rgb(252, 220, 216); // pink
  // $bgdark: rgba(252, 220, 216, 0.3);
  $bg: rgb(216, 226, 252);
  $bgdark: rgba(216, 226, 252, 0.3);

  // Will edit everything selected including everything between a range of dates
  .CalendarDay__selected_span {
    background: $bgdark !important;
    color: black !important; //text
    border: 1px solid black !important; //default styles include a border
  }

  // Will edit selected date or the endpoints of a range of dates
  .CalendarDay__selected {
    background: $bg !important;
    color: black !important;
    border: 1px solid black !important;
  }

  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    background: $bgdark !important;
    color: black !important;
    border: 1px solid black !important;
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: $bgdark !important;
    color: black !important;
    border: 1px solid black !important;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $bg !important;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $bg !important;
  }

}
