// for card styles see AdjustMode

$break: 600px;

.ShowItems {

  background: inherit; // need this for position sticky
  // padding: 0 0 0 1em;
  padding-left: 0.5em;
  overflow: auto;
  
  display: flex;
  flex-direction: column;
  height: 100%;

  // see also .DisplayCount and .InventoryCount height - keep in synch
  .cards {
    // padding-top: 5em;
    margin-top: 5em;
  }

  // .card.dimmed {
  //   opacity: 0.5;
  // }
  // .AdjustMode {
  //   // background: inherit;
  //   // user-select: none;
  // }

  .footer {
    font-size: 0.8em;
    @media (min-width: $break) { font-size: 0.9em }
    padding: 0.5em;
    span+span {
      padding-left: 2em;
    }
  }
}
