.superheader {
  text-align: center;
  color: #aaa;
  background: black;
  font-size: 1.1em;
}

.OrderHeader {
  color: white;
  background: black;
  font-size: 1.2em;
  padding: 2px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 1.8em;
  // flex-basis: 1.8em;
  flex-shrink: 0;

  .link {
    cursor: pointer;
  }
  .link:hover {
    color: gray;
  }
  .disabled {
    pointer-events: none;
  }

  .left {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .left img {
    height: 32px;
    // margin-bottom: -1px;
  }
  .left span {
    // margin-left: 8px;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .favs {
    color: gray;
    font-size: 1.4em;
    text-decoration: none;
  }
  .favs:hover {
    color: #aaa;
  }
  .nitems {
    text-decoration: none;
    margin-left: 4px;
  }

}
