$break: 600px;

.inventory-count-header {
  // position: sticky;
  // top: 0; // need this with position sticky
  // background: inherit; // ditto
  // z-index: 1; // ditto

  // fix ipad issues
  position: absolute;
  left: 0.5em;
  height: 4em; // see also .AdjustMode margin-top - keep in synch
  width: calc(100% - 1.5em); // leave space for scrollbar
  z-index: 1;
  background: inherit;

  padding: 0.5em 1em 0.5em 0;
  user-select: none; // prevent longpress from selecting any text

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // .info {
    //   padding-top: 0.5em;
    //   color: #009;
    //   cursor: pointer;
    //   display: block;
    //   @media (min-width: $break) { display: none; }
    // }

    // .instructions {
    //   padding: 0.5em 0 0.5em;
    //   display: none;
    //   @media (min-width: $break) { display: block }
    // }

    .buttons {
      padding: 0.5em 0 0.5em;
      span + span {
        margin-left: 10px;
      }
      button {
        height: 32px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 8px;
      }
      button + button {
        margin-left: 4px;
      }
      .ItemSearchDropdown {
        margin-left: 8px;
      }
    }
  }

  .camera-button {
    margin-left: 8px;
    width: 9em;
    img {
      height: 24px;
      margin-right: 6px;
    }
  }

  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: gray;
    }
  }

  .bottom {
    margin: 0 0 0.5em 0;
    .title {
      display: none;
      @media (min-width: $break) {
        display: inline;
      }
    }
    input[type='text'] {
      margin-top: 4px;
      border-radius: 3px;
    }
  }
}
