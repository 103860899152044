$break: 768px;

.app-header {
  width: 100%;
  flex-shrink: 0;
  flex-basis: 2em;
  color: #eee;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  user-select: none;

  .left {
    margin-left: 8px;
    display: flex;
    align-items: flex-end;

    .home {
      a {
        color: white;
        text-decoration: none;
      }
      .large {
        height: 36px;
      }
      .small {
        display: none;
        height: 35px;
        background-size: cover;
        z-index: 1;
        margin-bottom: 4px;
      }
      @media (max-width: $break) {
        .large {
          display: none;
        }
        .small {
          display: block;
        }
      }
    }
  }

  // make gradients at https://cssgradient.io/gradient-backgrounds/
  // background: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  // background: linear-gradient(90deg, #818aea 0%, #efd5ff 100%);

  .right {
    display: flex;
    align-items: center;
    text-align: right;
    color: black;
  }
  .test-message {
    margin-right: 4px;
  }
}

// .app-header.development {
//   background: #35c920;
// }
.app-header.development .test-message {
  color: #66f936;
}
