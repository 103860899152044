.MaintainInventories {
  height: 100%;
  overflow: auto;
  
  .actions button+button {
    margin-left: 4px;
  }
  .header {
    padding: 4px;
    display: flex;
  }
  .footer {
    padding: 4px;
    display: flex; // this is essential for ios
  }
}
