
// $width: 150px;
$width: 120px;
$extrawidth: 200px;

.ItemSearchDropdown {
  display: flex;
  align-items: center;
  .label {
    margin-right: 4px;
  }

  .suggestion {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #eee;
    span+span {
      margin-left: 4px;
    }
    img {
      flex-basis: 100px;
      flex-shrink: 0;
      width: 100px;
      height: 100px;
      object-fit: contain;
      margin-right: 4px;
    }
    .name, .barcode, .source {
      color: #999;
    }
  }

}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: $width;
  height: 30px;
  padding: 4px 8px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 3px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  max-height: 500px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  left: -$extrawidth;
  // width: 280px;
  width: calc(100% + #{$extrawidth});
  border: 1px solid #aaa;
  background-color: #fff;
  font-size: 16px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  // padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

