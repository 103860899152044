.dashboard {
  padding: 1em;
  overflow: auto;
  
  .dashboard-header {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;

    // .auditors {
    //   padding-top: 2px;
    // }
    // .auditor {
    //   display: flex;
    //   align-items: center;
    //   // height: fit-content;
    //   // svg:last-child {
    //   //   cursor: pointer;
    //   //   height: 15px;
    //   //   vertical-align: super;
    //   // }
    //   // & * {
    //   //   vertical-align: middle;
    //   // }
    //   // span {
    //   //   margin-left: 5px;
    //   // }
    // }
    // .auditor-logout {
    //   margin-left: 4px;
    // }
  }

  .instructions {
    // border-bottom: 1px solid #ccc;
    font-weight: bold;
    span {
      white-space: nowrap;
      font-weight: normal;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .upload-file {
    display: none;
    .label {
      // font-weight: bold;
      margin-bottom: 4px;
    }
    @media (min-width: 600px) {
      display: block;
    }
  }

  .store {
    margin: 1em 0 0 0;
    .store-header {
      margin-bottom: 0.5em;
      .name {
        font-size: 1.2em;
      }
      .stagedAt {
        font-size: 0.9em;
      }
    }
  }

}

// .contents:before {
//   content: ' ';
//   display: block;
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0.5;
//   background-image: url('../../assets/images/store.jpg') !important;
//   background-repeat: no-repeat;
//   background-position: 50% 0;
//   background-size: cover;
// }

.InventoryCount.card {
  $left: 4px;
  flex-direction: column;
  // // show image
  // $left: 74px;
  // background-image: url('../../assets/images/rings2.jpg');
  // background-repeat: no-repeat;
  // background-size: auto 100%;
  .card-category {
    font-size: 0.9em;
    width: calc(100% - #{$left});
    // position: absolute;
    // left: $left;
    // top: 30px;
  }
  .card-store {
    font-size: 0.8em;
    color: #999;
    // position: absolute;
    // top: 54px;
    // left: $left;
    // right: $left;
  }
  .card-start {
    font-size: 0.8em;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .card-status {
    font-size: 0.9em;
    // font-weight: bold;
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .card-play {
    position: absolute;
    bottom: 4px;
    left: $left;
  }
  .card-play button[disabled] {
    color: #ddd;
  }
  .card-delete {
    position: absolute;
    bottom: 4px;
    left: calc(#{$left} + 28px);
  }
}
