$header-height: 6.5em;
$category-width: 28em;
$category-dropdown-width: 20em;
$imagesize: 100px;
$card-height: 5em;


.EditCategory {
  background: inherit;
  overflow-y: auto;

  .header {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    // position: sticky;
    // top: 0;
    // z-index: 1;
    // background: inherit;

    // fix ipad issues
    position: absolute;
    // left: 0.5em;
    height: $header-height;
    // width: calc(100% - 1em); // leave space for scrollbar
    width: 100%;
    z-index: 1;
    background: inherit;
    
    
    .top {
      display: flex;
      justify-content: space-between;
      padding: 0.5em;
      button+button {
        margin-left: 4px;
      }
    }

    .bottom {
      position: relative;
      border-bottom: 1px solid #ccc;
      .name {
        font-weight: bold;
        color: #444;
        font-size: 1.1em;
      }
      .number {
        color: gray;
      }
      .category {
        padding-top: 4px;
      }
      .source {
        color: gray;
      }
      .left {
        position: absolute;
        left: 1em;
      }
      .right {
        position: absolute;
        left: calc(#{$category-width} + 3em); //. or 3 if left has no scrollbar
      }
    }
  }

  .body {
    padding-top: $header-height;
    height: calc(100% - #{$header-height});
    overflow-x: hidden;
    // padding-left: 1em;
    display: flex;
    .category {
      padding-left: 1em;
      + .category {
        margin-left: 1em;
      }
    }  
    .category div {
      // width: $category-width;
    }
  }

  
  #left-container, #right-container {
    height: 100%;
    overflow-y: auto;
  }

  .category-dropdown {
    display: flex;
    align-items: center;
    padding: 0 1em;
    >div {
      width: $category-dropdown-width;
    }
  }

  .items {
    width: $category-width;
    // div:first-child {
    //   height: 1em;
    //   >div {
    //     display: none;
    //   }
    //   // display: none;
    // }
    .placeholder {
      // background: white;
      color: gray;
      margin-bottom: 8px;
    }
  }

  .CategoryItemCard {
    width: calc(#{$category-width} - 38px); // hard to get good width due to scrollbars
    background: white;
    padding: 4px 8px;
    margin-bottom: 6px;
    box-shadow: 3px 3px 3px #ccc;
    position: relative;
    height: $card-height;
    display: flex;
    &.selected {
      background: #ecf0ff;
    }
    .image {
      margin-right: 8px;
      img {
        width: $imagesize;
        height: $imagesize;
        object-fit: contain;
      }
    }
    .code {
      // font-weight: bold;
    }
    .name {
      font-size: 0.9em;
      color: #aaa;
    }
    .barcode {
      font-size: 0.9em;
      color: #aaa;
    }
    .source {
      font-size: 0.9em;
      color: #aaa;
    }
    .position {
      position: absolute;
      right: 8px;
      bottom: 4px;
      color: #ccc;
      font-size: 0.9em;
      input[type="text"] {
        color: #aaa;
        width: 3em;
        text-align: center;
      }
    }
    .corner {
      font-size: 0.8em;
      position: absolute;
      right: 8px;
      top: 4px;
    }
  }

}
