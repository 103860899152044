$imgsize: 100px;
$left: $imgsize + 8px;
$height: $imgsize + 8px;

.OrderItems {
  // margin: 1em auto;
  padding: 1em;

  .header {
    // font-size: 1.1em;
    margin-bottom: 1em;
  }

  .card {
    background: #fafafa; // in between f5f5f5 and white - some have one some the other
    height: $height;
    position: relative;
    padding: 0;
    text-align: left;

    .name {
      position: absolute;
      left: $left;
      // top: 4px;
      // bottom: 20px;
      top: 45%;
      right: 4px;
      transform: translate(0,-55%);
      font-size: 0.8em;
    }
    .code {
      position: absolute;
      bottom: 4px;
      left: $left;
      font-size: 0.7em;
      color: gray;
    }
    .price {
      position: absolute;
      bottom: 4px;
      right: 4px;
      font-size: 0.7em;
      color: gray;
    }
    .image {
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      img {
        height: $imgsize;
        width: $imgsize;
        object-fit: contain;
      }
    }
    .heart {
      position: absolute;
      font-size: 1.4em;
      top: 4px;
      right: 4px;
    }
  }

  .continue {
    text-align: center;
    button {
      height: 2em;
      padding: 0 1em;
    }
  }
}
