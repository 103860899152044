$header-height: 2.5em;

.ShowDisplays {
  // padding: 0 0 0 4px;
  padding-left: 4px;
  background: inherit;
  user-select: none;
  -webkit-touch-callout: none;
  overflow: auto;

  .ShowDisplays-header {
    // flex-basis: 8em;
    // padding: 10px;
    // padding: 0.5em 1em 0.5em 0;
    padding-top: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    // sticky nowork on ios
    // position: sticky;
    // top: 0;
    // z-index: 1;
    // background: inherit;

    // fix ipad issues
    position: absolute;
    left: 0.5em;
    height: $header-height;
    width: calc(100% - 1.5em); // leave space for scrollbar
    z-index: 1;
    background: inherit;
    
    .buttons {
      display: flex;
      button+button {
        margin-left: 4px;
      }
      button+span {
        margin-left: 8px;
      }
    }
  }

  .cards-list {
    margin-top: calc(#{$header-height} + 1em); // 1em for the header padding
  }
  
  .ItemSearchCard {
    .locationName, .locationNumber, .currentCount {
      color: gray;
    }
  }

}


.DisplayCard {
  // color: white;
  // text-shadow: 0 2px 2px black;
  // box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.3);
  // background-image: url('../../assets/images/tray2.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  
  // show icon
  background-image: url('../../assets/images/display2.jpg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 6px 6px;
  box-shadow: inset 0 0 0 1000px rgba(255,255,255,0.7); // dim out the icon a bit

  // // show image
  // background-image: url('../../assets/images/display.jpg');
  // background-repeat: no-repeat;
  // background-size: auto 100%;  
}

// .DisplayCard-name {
.DisplayCard-number {
  font-size: 0.7em;
  position: absolute;
  top: 8px;
  left: 24px;
  right: 24px;
}
// .DisplayCard-number {
.DisplayCard-name {
  width: 100%;
}
.DisplayCard-unique {
  font-size: 0.7em;
  position: absolute;
  bottom: 8px;
  left: 8px;
}
.DisplayCard-sum {
  font-size: 0.7em;
  position: absolute;
  bottom: 8px;
  right: 8px;
}


.addDisplay {
  background: white;
  // width: 50%;
  width: 20em;
  max-width: 90%;
  padding: 1em;
  h3 {
    margin: 0;
  }
  input[type="text"] {
    max-width: 90%;
  }
  .select-store {
    margin: 10px 0;
  }
  .addDisplay-options {
    div {
      p {
        // opacity: 65%;
        color: #777;
        margin: 5px 0;
      }
      display: flex;
      flex-direction: column;
    }
  }
}
.addDisplay-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
