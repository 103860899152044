.MaintainEmployees {
  height: 100%;

  .header {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    .show-status {
      padding: 4px;
    }
    .right {
      // display: flex;
      // justify-content: flex-end;
      padding: 4px;
      button + button {
        margin-left: 4px;
      }  
    }
  }

  .footer {
    display: flex;
    justify-content: flex-start;
    padding: 4px;
  }

  .ag-header-cell-text {
    white-space: normal; // let header text wrap for multiline
  }

  .actions {
    button+button {
      margin-left: 4px;
    }
  }
}
