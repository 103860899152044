.InventoryReview {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex; // need for ios
    flex: 1 1 auto; //. needed?
    justify-content: space-between; // keeps buttons on right edge
    padding: 2px 4px;

    .buttons {
      margin-top: 8px;  
      span+span {
        margin-left: 10px;
      }
      a {
        color: black;
        text-decoration: none;
      }
      a:hover {
        color: gray;
      }
    }  
    
    table {
      td {
        text-align: left;
        vertical-align: top;
        border: 0px;
        padding: 4px;
        padding-right: 1em;
      }
    }
  
    @media (max-width: 550px) {
      font-size: 1rem;
      .buttons {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
      }
    }
  }

  .footer {
    display: flex; // need for ios
    flex: 1 1 auto; //. needed?
    padding: 2px 4px;
    @media (max-width: 550px) {
      font-size: 1rem;
    }
    table {
      width: 100%;
    }
  }

  .ag-header-cell-text {
    white-space: normal; // let header text wrap for multiline
  }

  .alert-icons {
    margin-top: 2px;
  }

}

.ag-header-group-cell-label {
  justify-content: center;
  color: black;
}

.ag-floating-top .ag-row, 
.ag-floating-bottom .ag-row {
  font-weight: bold;
}
