.account {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //height: 100%;
  padding: 1em;

  td input {
    width: 15em;
  }

  td:first-child {
    font-weight: bold;
  }
  td+td {
    padding-left: 1em;
  }

  button {
    // margin-top: 1em;
    // margin-right: 0.5em;
    // margin-bottom: 1em;
    margin-left: 4px;
  }
}
