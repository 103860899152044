.getChoice {
  background: white;
  // width: 50%;
  width: 20em;
  max-width: 90%;
  padding: 1em;
  max-height: 90%;
  overflow-y: auto;
  h3 {
    margin: 0;
  }
  // input[type="text"] {
  //   max-width: 90%;
  // }
  .choice.selected {
    background: lightblue;
  }
  .buttons {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
  button+button {
    margin-left: 0.5em;
  }
}
